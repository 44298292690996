import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { Helmet } from 'react-helmet';
import Favicon from '../assets/img/favicon.ico';
import KebijakanPrivasi from '../componentsAtomic/templates/V2/PrivacyPolicy';
import useDetectId from '../hooks/useDetectId';
import NotFound from './404';

const Render = () => {
	const intl = useIntl();
	const { isId } = useDetectId();
	if (isId) return <NotFound />;

	return (
		<>
			<Helmet title={intl.formatMessage({ id: `privacy_policy.meta.title` })}>
				<meta name="description" content={intl.formatMessage({ id: `privacy_policy.meta.desc` })} />
				<meta name="author" content="Chatbiz" />
				<meta name="theme-color" content="#04589b" />
				<link rel="icon" href={Favicon} />
				<html lang="id" />
				<meta name="robots" content="index, follow" />
			</Helmet>
			<KebijakanPrivasi />
		</>
	);
};

export default Render;

// import React, { useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet';
// import 'typeface-montserrat';
// import 'bootstrap/dist/css/bootstrap.css';
// import '../assets/_homepage/sass/main.scss';

// import 'lazysizes';

// import Navbar from '../components/_homepage/navbar';
// import SideBar from '../components/_homepage/sidebar';
// import GeneralTXT from '../components/_homepage/GeneralTxt';
// import ModalRequest from '../components/_homepage/modalRequest';
// import Footer from '../components/_homepage/footer';
// import Favicon from '../assets/img/favicon.ico';

// const txtHtml = `
// <p><span>PENDAHULUAN</span></p>
// <p>Chatbiz.id sangat berkomitmen melindungi data privasi Anda secara penuh. Dengan begitu di sini kami bermaksud menjelaskan kepada Anda perihal bagaimana kami menggunakan data pribadi Anda.</p>
// <p>Kami sarankan agar Anda membaca kebijakan privasi ini hingga selesai sehingga Anda bisa memahami pengelolaan data pribadi Anda di layanan Chatbiz.id.</p>
// <p>&nbsp;</p>
// <p><span>CAKUPAN KEBIJAKAN PRIVASI</span></p>
// <ul>
// <li>Kebijakan Privasi ini berlaku di semua bagian website dan layanan Chatbiz.id</li>
// <li>Kebijakan Privasi ini tidak berlaku untuk website dan layanan selain Chatbiz.id.&nbsp;<br /> Perlu diketahui bahwa di website Chatbiz.id terdapat berbagai layanan dari pihak ketiga yang disediakan untuk memaksimalkan pengalaman Anda dalam berselancar di website kami. Chatbiz.id tidak bertanggung jawab terhadap penanganan informasi pribadi di website-website tersebut. Kami menyarankan Anda untuk selalu melihat Kebijakan Privasi website-website tersebut sebelum Anda memutuskan untuk memberikan data pribadi Anda.</li>
// </ul>
// <p>&nbsp;</p>
// <p><span>INFORMASI YANG KAMI AMBIL DARI PENGGUNA</span></p>
// <p>Ada 2 tipe informasi yang kami ambil dari pengguna.</p>
// <ul>
// <li>Informasi yang diambil oleh sistem ketika Anda sedang browsing website Chatbiz.id.</li>
// <li>Informasi yang diambil oleh sistem ketika Anda sedang menggunakan layanan Chatbiz.id</li>
// </ul>
// <p>&nbsp;</p>
// <p><span>INFORMASI YANG DIAMBIL OLEH SISTEM</span></p>
// <p><span>Cookies</span></p>
// <p>Cookies adalah&nbsp;serangkaian teks yang disimpan pada komputer Anda oleh website kami yang&nbsp;berkaitan dengan informasi pengguna. Cookies ini digunakan untuk menyesuaikan, mempermudah Anda seperti mengingat pengaturan, password secara otomatis dan manajemen sesi.</p>
// <p>Cookies ini digunakan juga untuk keperluan statistik pengunjung. Mengetahui berapa jumlah pengunjung yang datang, waktu berkunjung, durasi kunjungan, dan halaman yang dibuka pengunjung di website ini. Analisa statistik ini kami gunakan untuk meningkatkan kualitas website ini agar semakin sesuai dengan kebutuhan pengunjung.</p>
// <p>Namun, Anda bisa untuk menerima atau menolak kebijakan cookies ini. Dasarnya, web browser secara otomatis menerima cookies, tetapi Anda bisa memodifikasi untuk menolaknya jika Anda tidak menginginkannya melalui add-ons pihak ketiga. Namun dengan menolak cookies akan membuat pengalaman berselancar di website kami menjadi terbatas.</p>
// <p>Kami berjanji data yang kami terima tidak akan kami berikan kepada siapa pun. Kami pun tidak pernah menyimpan data pribadi Anda yang tidak ingin Anda berikan kepada kami.</p>
// <p><span>IP Addresses</span></p>
// <p>IP Address merupakan variasi deretan angka yang dipakai sebagai identifikasi tiap komputer host dalam jaringan internet. Kami merekam setiap IP Address pengguna untuk keperluan statistik pengunjung, analisa tren, dan analisa demografi. Rekam IP Address tidak pernah digunakan untuk mengidentifikasi informasi pribadi pengunjung. kami tidak akan pernah memanfaatkan IP Anda untuk keperluan bisnis dan hal yang merugikan lainya.</p>
// <p><span>Data Diri, E-mail dan Nomor WhatsApp</span></p>
// <p>Chatbiz.id tidak akan pernah menyebarkan, menjual, menyewakan, menukar atau memberikan data diri, e-mail dan nomor WhatsApp Anda kepada pihak ketiga tanpa persetujuan Anda.</p>
// <p>Data diri, e-mail dan nomor WhatsApp Anda kami dapatkan jika Anda menggunakan layanan di website kami, jika tidak kami tidak pernah meminta tanpa pemberitahuan dan kesukarelaan pengguna. Data diri, e-mail dan nomor WhatsApp Anda akan selalu kami jaga kerahasiaannya.</p>
// <p><span>Newsletter</span></p>
// <p>Jika nanti Anda berlangganan Newsletter di website ini, maka Anda akan menerima e-mail setiap ada konten baru yang diterbitkan di Chatbiz.id. Jika Anda tidak lagi menginginkannya, Anda bisa klik &ldquo;Unsubscribe&rdquo; di sudut bawah setiap newsletter.</p>
// <p>------</p>
// <p>Demikianlah Kebijakan Privasi penggunaan website Chatbiz.id. Sekali lagi kami sangat berkomitmen dengan Privasi Anda, bagi kami Privasi adalah hal yang sangat penting. Dengan Anda mengunjungi website kami, berarti Anda telah menyetujui kebijakan yang berlaku di atas.</p>
// <p>Kebijakan privasi ini akan terus diperbaharui jika diperlukan, maka harap pantau terus laman ini untuk pembaharuan kebijakan kami, Kami juga akan memberitahukan jika kebijakan privasi kami telah diperbaharui.</p>
// <p>Jika Anda masih memiliki pertanyaan terkait kebijakan privasi kami, silakan hubungi kami melalui tautan yang tersedia, dengan senang hati kami akan menjawab pertanyaan Anda.</p>
// `;

// const KetentuanLayanan = () => {
// 	const [isSideBar, setSideBar] = useState(false);
// 	const [isModalDemo, setModalDemo] = useState(false);
// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, []);
// 	return (
// 		<>
// 			<Helmet title="Kebijakan Privasi | Chatbiz.id">
// 				<meta
// 					name="description"
// 					content="Chatbiz.id sangat berkomitmen melindungi data privasi Anda secara penuh. Dengan begitu di sini kami bermaksud menjelaskan kepada Anda perihal bagaimana kami menggunakan data pribadi Anda."
// 				/>
// 				<meta name="author" content="Chatbiz" />
// 				<meta data-vue-tag="ssr" name="theme-color" content="#04589b" />
// 				<link rel="icon" href={Favicon} />
// 			</Helmet>
// 			<div id="HomePageChatBiz" className="contentHomePage">
// 				<SideBar isSideBar={isSideBar} setSideBar={setSideBar} setModalDemo={setModalDemo} />
// 				<Navbar setSideBar={setSideBar} setModalDemo={setModalDemo} />
// 				<GeneralTXT txtHtml={txtHtml} title="Kebijakan Privasi" modified="3 September 2020" />
// 				<Footer />
// 				{isModalDemo && <ModalRequest setModalDemo={setModalDemo} />}
// 			</div>
// 		</>
// 	);
// };

// export default KetentuanLayanan;
